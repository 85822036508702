import React from 'react';
// import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { DatePicker } from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton';
import { NavigateNext } from '@material-ui/icons';

export default class Signin extends React.Component {

	constructor(props) {
		super(props);
		let newDate = DateTime.local().startOf('day');
		this.state = {
			currentDate: newDate
		};
		this.updateParent(newDate);
	}

	updateParent = (currentDate) => {
		if (this.props.onChange) {
			this.props.onChange(currentDate);
		}
	}

  	onChangeDate = (date) => {

  		let newDate = date.startOf('day');
  		this.setState({
			currentDate: newDate
		});
		this.updateParent(newDate);
	}

	tomorrow = () => {
		let newDate = this.state.currentDate.plus({days: 1}).startOf('day');
  		this.setState({
			currentDate: newDate
		});
		this.updateParent(newDate);
	}

	render() {

	    return <MuiPickersUtilsProvider utils={LuxonUtils}>
	    	<div className="DaySelector">
	    		<DatePicker value={this.state.currentDate} onChange={this.onChangeDate} format="dd MMM yyyy" disablePast={true} />
    			<IconButton onClick={this.tomorrow} color='inherit' >
    				<NavigateNext />
	    		</IconButton>
	    	</div>
	    </MuiPickersUtilsProvider>;
  	}
}
