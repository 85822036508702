import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export default class Signin extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
		 	password: ''
		};
	}

  	onChangeEmail = (e) => {
		this.setState({
			email: e.currentTarget.value
		});
	}

	onChangePassword = (e) => {
		this.setState({
			password: e.currentTarget.value
		});
	}

	onClickSignIn = () => {

		// validate!

		// try sign-in
		window.firebaseApp.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
		  console.error(error.code + ": " + error.message)
		  alert(error.message);
		});
	}

  	render() {

	    return <div className="Signin" style={{width: 200}}>

	    	<h1 className="align-middle">Sign in</h1>
	    	<div className="field">
			  <TextField fullWidth={true} required id="email" variant="outlined" label="Email Address" onChange={this.onChangeEmail} />
			</div>
			<div className="field">
			  <TextField fullWidth={true} required id="password" variant="outlined" label="Password" type="password" onChange={this.onChangePassword} />
			</div>
			<div className="field">
			  <Button fullWidth={true} variant="contained" color="primary" onClick={this.onClickSignIn}>Sign in</Button>
			</div>

	    </div>;
  	}
}


