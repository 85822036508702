import React from 'react';
// import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import FormattedStringInput from './FormattedStringInput';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { TimePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export default class SailingList extends React.Component {

	// expected list, onChange
	// constructor(props) {
	// 	super(props);
	// }

	updateParent = (list) => {
		if (this.props.onChange) {
			this.props.onChange(list);
		}
	}

	// add a new line
	addLine = () => {
		var list = this.props.list.slice();
		list.push({
			"cargo": "Passengers",
			"departing": "Guernsey",
			"passengers": "",
			"time": "00:00 AM",
			"vessel": "",
            "additional": ""
		});
		this.updateParent(list);
	}

	removeLine = (index) => {
		var list = this.props.list.slice();
		list.splice(index, 1);
		this.updateParent(list);
	}

	onChangePassengers = (index, text) => {
		var list = this.props.list.slice();
		list[index].passengers = text;
		this.updateParent(list);
	}

	onChangeDeparting = (index, text) => {
		var list = this.props.list.slice();
		list[index].departing = text;
		this.updateParent(list);
	}

	onChangeVessel = (index, text) => {
		var list = this.props.list.slice();
		list[index].vessel = text;
		this.updateParent(list);
	}

	onChangeTime = (index, time, timeString) => {
		var list = this.props.list.slice();
		list[index].time = time.toFormat("H:mm");
		this.updateParent(list);
	}

	onChangeCargo = (index, value) => {
		var list = this.props.list.slice();
		list[index].cargo = value;
		this.updateParent(list);
	}

    onChangeAdditional = (index, text) => {
		var list = this.props.list.slice();
		list[index].additional = text;
		this.updateParent(list);
	}

	render() {

      //   <FormControlLabel
      //       control={<Checkbox
      //   checked={item.cargo}
      //   color="primary"
      //   onChange={(event) => {
      //       let target = event.currentTarget;
      //       this.onChangeCargo(index, target.checked);
      //   }}
      // />}
      //       label="Cargo"
      //     />
		return <div className="SailingList"><MuiPickersUtilsProvider utils={LuxonUtils}>
            <div>
			{this.props.list.map((item, index) => {

				var xmTime = item.time;
				if (xmTime.includes(" XM")) {
					xmTime = xmTime.replace(" XM", "")
				}

				return <div className="field" key={"sailing-"+index}>

					<TimePicker
			          label="Time"
			          value={xmTime.includes(" ") ? DateTime.fromFormat(xmTime, "hh:mm a") : DateTime.fromFormat(xmTime, "H:mm")}
					  ampm={false}
			          onChange={(time, timeString) => {
			          	this.onChangeTime(index, time, timeString);
			          }}
			        />

			        <FormControl>
					<InputLabel shrink >Departing</InputLabel>
					<Select
					value={item.departing}
					onChange={(event) => {
						let f = event.target.value;
						this.onChangeDeparting(index, f);
					}}
					>
					<MenuItem value={'Guernsey'}>Guernsey</MenuItem>
					<MenuItem value={'Sark'}>Sark</MenuItem>
					</Select>
					</FormControl>

					<TextField value={item.vessel} label="Vessel" onChange={(event) => {
						let target = event.currentTarget;
						this.onChangeVessel(index, target.value);
					}} />

					<TextField value={item.passengers} label="Passengers" onChange={(event) => {
						let target = event.currentTarget;
						this.onChangePassengers(index, target.value);
					}} />

                    <FormControl>
					<InputLabel shrink >Cargo/Passengers</InputLabel>
					<Select
					value={item.cargo}
					onChange={(event) => {
						let f = event.target.value;
						this.onChangeCargo(index, f);
					}}
					>
					<MenuItem value={'Both'}>Both</MenuItem>
					<MenuItem value={'Cargo'}>Cargo</MenuItem>
                    <MenuItem value={'Passengers'}>Passengers</MenuItem>
					</Select>
					</FormControl>

                    <IconButton size="small" onClick={(e) => {
						this.removeLine(index);
					}}>
						<RemoveCircleIcon />
					</IconButton>

                    <TextField value={item.additional} label="Additional Info" onChange={(event) => {
						let target = event.currentTarget;
						this.onChangeAdditional(index, target.value);
					}} />

				</div>
			})}

			<Button color="primary" variant="contained" onClick={this.addLine} startIcon={<AddCircleIcon />}>Add Sailing</Button>
            </div>
		</MuiPickersUtilsProvider>
		</div>
	}
}
