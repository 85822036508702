import React from 'react';
// import Button from '@material-ui/core/Button';
//import { DateTime } from 'luxon';
//import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';


// text inout plus selection of..
// header, subheader, bold, divider, bold & red, link
export default class FormattedStringInput extends React.Component {

	// expected text and onChange
	constructor(props) {
		super(props);		
	}

	updateParent = (text) => {
		if (this.props.onChange) {
			this.props.onChange(text);
		}
	}

	onChange = (event) => {
		let target = event.currentTarget;
		this.updateParent(target.value);
	}

	render() {
		return <div className="FormattedStringInput">

			<TextField value={this.props.text} fullWidth={true} label="" onChange={this.onChange} />

		</div>
	}
}
