import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { DateTimePicker } from '@material-ui/pickers'

export default class CruiseList extends React.Component {

	// expected list, onChange
	// constructor(props) {
	// 	super(props);
	// }

	updateParent = (list) => {
		if (this.props.onChange) {
			this.props.onChange(list);
		}
	}

	// add a new line
	addLine = () => {
		var list = this.props.list.slice();
		list.push({
			"passengers": "",
			"arrival": DateTime.local().ts,
            "departing": DateTime.local().ts,
			"vessel": "",
            "additional": ""
		});
		this.updateParent(list);
	}

	removeLine = (index) => {
		var list = this.props.list.slice();
		list.splice(index, 1);
		this.updateParent(list);
	}

	onChangePassengers = (index, text) => {
		var list = this.props.list.slice();
		list[index].passengers = text;
		this.updateParent(list);
	}

	onChangeVessel = (index, text) => {
		var list = this.props.list.slice();
		list[index].vessel = text;
		this.updateParent(list);
	}

    onChangeArrival = (index, datetime) => {
		var list = this.props.list.slice();
		list[index].arrival = datetime.ts;
		this.updateParent(list);
	}

    onChangeDeparting = (index, datetime) => {
		var list = this.props.list.slice();
		list[index].departing = datetime.ts;
		this.updateParent(list);
	}

	onChangeAdditional = (index, text) => {
		var list = this.props.list.slice();
		list[index].additional = text;
		this.updateParent(list);
	}

	render() {

		return <div className="SailingList"><MuiPickersUtilsProvider utils={LuxonUtils}>
            <div>
			{this.props.list.map((item, index) => {

				return <div className="field" key={"sailing-"+index}>

                <TextField value={item.vessel} label="Vessel" onChange={(event) => {
                    let target = event.currentTarget;
                    this.onChangeVessel(index, target.value);
                }} />

                <DateTimePicker
                  label="Arrival"
                  value={DateTime.fromMillis(item.arrival)}
				  ampm={false}
                  onChange={(datetime) => {
                    this.onChangeArrival(index, datetime);
                  }}
                />

                <DateTimePicker
                  label="Departing"
                  value={DateTime.fromMillis(item.departing)}
				  ampm={false}
                  onChange={(datetime) => {
                    this.onChangeDeparting(index, datetime);
                  }}
                />

				<TextField value={item.passengers} label="Passengers" onChange={(event) => {
					let target = event.currentTarget;
					this.onChangePassengers(index, target.value);
				}} />


                <IconButton size="small" onClick={(e) => {
					this.removeLine(index);
				}}>
					<RemoveCircleIcon />
				</IconButton>

                <TextField value={item.additional} label="Additional Info" onChange={(event) => {
					let target = event.currentTarget;
					this.onChangeAdditional(index, target.value);
				}} />

			</div>
			})}

			<Button color="primary" variant="contained" onClick={this.addLine} startIcon={<AddCircleIcon />}>Add Cruise Ship</Button>
            </div>
		</MuiPickersUtilsProvider>
		</div>
	}
}
