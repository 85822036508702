import React from 'react';
import Signin from './Signin';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { DateTime } from 'luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import StaticFields from './StaticFields';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import EjectIcon from '@material-ui/icons/Eject';

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#145da0',
        //main: '#042540',
        //main: '#081b66'
    },
    secondary: {
      main: '#333333',
    },
  },
});

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.dataRoot = '/appData-v2/';
    this.dataRootStatic = '/appData-v2/static/';
    this.state = {
      user: null,
      loading: true,
      loadedData: null,
      saveSnackOpen: false
    };
  }

  componentDidMount = () => {
    window.firebase.auth().onAuthStateChanged(function(user) {
        this.setState({
          user: user,
          loading: true
        });
        this.loadData();
      }.bind(this));
  }

  signOut = (e) => {
    window.firebase.auth().signOut();
  }

  loadData = (date) => {
    let database = window.firebase.database();
    database.ref(this.dataRootStatic).once('value').then(function(snapshot) {
      let data = snapshot.val();
      this.setState({
        loadedData: data,
        loading: false
      });
    }.bind(this));
  }

  saveData = (data) => {

    let database = window.firebase.database();
    database.ref(this.dataRootStatic).set(data).then(() => {
      this.setState({
        saveSnackOpen: true
      });
    });
  }

  onChangeField = (fieldname, text) => {

      let fields = Object.assign({}, this.state.loadedData);
      fields[fieldname] = text;

      this.setState({
          loadedData: fields
      });
  }

  saveSnackClosed = () => {
    this.setState({
      saveSnackOpen: false
    });
  }

  save = () => {
      this.saveData(this.state.loadedData);
  }

  reset = () => {
      this.loadData();
  }

  render() {

    if (!this.state.user) {

      // sign-in
      return <div className="App">
        <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
          <Typography variant="h6">
          Sark Shipping App CMS
          </Typography>
        </Toolbar>
      </AppBar>
        <Container maxWidth="md">
          <div className="centred">
            <Signin />
          </div>
        </Container>
        </ThemeProvider>
      </div>;
    }

    // content
    let content = <div></div>;
    if (this.state.loading) {

      content = <div className="centred">
        <CircularProgress size={100} />
      </div>;

    } else if (this.state.loadedData) {
      content = <div>
        <StaticFields fields={this.state.loadedData} onChange={this.onChangeField} />
        <Divider />

        <div className="field saveButtons">

            <Button startIcon={<SaveIcon />} size="large" onClick={this.save} color="primary" variant="contained">Save</Button>
            <Button startIcon={<EjectIcon />} size="large" onClick={this.reset} color="secondary" variant="contained">Reset</Button>
        </div>
    </div>;
    }

    return <div className="App StaticApp">
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">Sark Shipping App CMS</Typography>
            <div className="DaySelector"></div>
            <Button color="inherit"><a href="/">Daily Data</a></Button>
            <Button color="inherit"><a href="/cruiseships">Cruise Ships</a></Button>
            <Button onClick={this.signOut} color="inherit">Sign-out</Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
          {content}
        </Container>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  open={this.state.saveSnackOpen}
                  autoHideDuration={2000}
                  onClose={this.saveSnackClosed}
                  action={<IconButton size="small" aria-label="close" color="inherit" onClick={this.saveSnackClosed}>
                    <CloseIcon fontSize="small" />
                  </IconButton>}>
          <Alert onClose={this.saveSnackClosed} severity="success">
            Saved
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </div>;
  }
}
