import React from 'react';
// import Button from '@material-ui/core/Button';
//import { DateTime } from 'luxon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormattedStringInput from './FormattedStringInput';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';

// a list of text inputs with options for type of...
// header, subheader, bold, divider, bold & red, link
export default class FormattedStringList extends React.Component {

	// expected list, onChange
	// constructor(props) {
	// 	super(props);
	// }

	updateParent = (list) => {
		if (this.props.onChange) {
			this.props.onChange(list);
		}
	}

	// add a new line
	addLine = () => {
		var list = this.props.list.slice();
		list.push("");
		this.updateParent(list);
	}

	removeLine = (index) => {
		var list = this.props.list.slice();
		list.splice(index, 1);
		this.updateParent(list);
	}

	onChangeFormat = (index, format) => {
		var list = this.props.list.slice();
		var item = this.extractFromText(list[index]);
		item.format = format;
		if (format === "div") {
			item.text = "";
		}

		if (format !== "") {
			list[index] = [item.format, item.text].join(":");
		} else {
			list[index] = item.text;
		}

		this.updateParent(list);
	}

	onChangeText = (index, text) => {
		var list = this.props.list.slice();
		var item = this.extractFromText(list[index]);
		item.text = text;

		if (item.format === "div") {
			item.text = "";
		}

		if (item.format !== "") {
			list[index] = [item.format, item.text].join(":");
		} else {
			list[index] = item.text;
		}

		this.updateParent(list);
	}

	extractFromText(text) {

		var format = '';
		var formatText = text.split(":");
		if (['h1', 'sh', 'st', 'b2', 'div'].includes(formatText[0])) {

			format = formatText[0];
			formatText.shift();
			formatText = formatText.join(':');

		} else {
			formatText = formatText.join(':');
		}

		return {
			format: format,
			text: formatText
		};
	}

	render() {

		return <div className="FormattedStringList">

			{this.props.list.map((text, index) => {

				let item = this.extractFromText(text);

				return <div className="field" key={"fsi-"+index}>

					<FormattedStringInput text={item.text} onChange={(text) => {
						this.onChangeText(index, text);
					}} />

					<Select
					value={item.format}
					onChange={(event) => {
						let f = event.target.value;
						this.onChangeFormat(index, f);
					}}
					displayEmpty
					>
					<MenuItem value={''}>Default</MenuItem>
					<MenuItem value={'h1'}>Header</MenuItem>
					<MenuItem value={'sh'}>Subheader</MenuItem>
					<MenuItem value={'st'}>Bold &amp; Red</MenuItem>
					<MenuItem value={'b2'}>Bold</MenuItem>
					<MenuItem value={'div'}>Divider</MenuItem>
					</Select>

					<IconButton size="small" onClick={(e) => {
						this.removeLine(index);
					}}>
						<RemoveCircleIcon />
					</IconButton>
				</div>
			})}

			<Button color="primary" variant="contained" onClick={this.addLine} startIcon={<AddCircleIcon />}>Add Line</Button>
		</div>
	}
}
