import React from 'react';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import FormattedStringList from './FormattedStringList';
import SailingList from './SailingList';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import EjectIcon from '@material-ui/icons/Eject';
import LuxonUtils from '@date-io/luxon';
import { DateTimePicker } from '@material-ui/pickers'

export default class Day extends React.Component {

	// expected data, onSave, currentDate
	constructor(props) {
		super(props);

		// pull the fields out of the props data
		this.state = this.getStateFromProps(props);
	}

	getStateFromProps = (props) => {

        let newState = {};
		newState['alert'] = [];
		if (props.data['alert']) {
			newState['alert'] = props.data['alert'].slice();
		}

		newState['service-message'] = [];
		if (props.data['service-message']) {
			newState['service-message'] = props.data['service-message'].slice();
		}

		newState['sailings'] = [];
		if (props.data['sailings']) {
			newState['sailings'] = props.data['sailings'].slice();
		}

		// newState['cruise-ships'] = [];
		// if (props.data['cruise-ships']) {
		// 	newState['cruise-ships'] = props.data['cruise-ships'].slice();
		// }

		newState['offers'] = [];
		if (props.data['offers']) {
			newState['offers'] = props.data['offers'].slice();
		}

        newState['updated-at'] = DateTime.local();
        if (props.data['updated-at']) {
            newState['updated-at'] = DateTime.fromMillis(props.data['updated-at']);
        }

		return newState;
	}

	updateServiceMessage = (list) => {
		this.setState({
			'service-message': list
		});
	}

	updateAlert = (list) => {
		this.setState({
			alert: list
		});
	}

	updateSailings = (list) => {

		// order the list by time field
		list = list.sort((a, b) => {

			var timeA;
			if (a.time.includes(" ")) {
				timeA = DateTime.fromFormat(a.time, "hh:mm a");	
			} else {
				timeA = DateTime.fromFormat(a.time, "H:mm");	
			}
			var timeB;
			if (b.time.includes(" ")) {
				timeB = DateTime.fromFormat(b.time, "hh:mm a");	
			} else {
				timeB = DateTime.fromFormat(b.time, "H:mm");	
			}
			
			if (timeA.hour === 0 && timeA.minute === 0) return 1;
			if (timeB.hour === 0 && timeB.minute === 0) return -1;
			return timeA - timeB;
		});

		this.setState({
			sailings: list
		});
	}

    // updateCruises = (list) => {
	// 	this.setState({
	// 		'cruise-ships': list
	// 	});
	// }

    // updatedAt = (value) => {
    //     console.log("set:");
    //     console.dir(value);
    //     this.setState({
	// 		'updated-at': value
	// 	});
	// }

	updateOffers = (list) => {
		this.setState({
			offers: list
		});
	}

	save = () => {

		let data = {};
		data['service-message'] = this.state['service-message'];
		data['sailings'] = this.state['sailings'].map (item => {
			if (item.time.includes("AM") || item.time.includes("PM")) {
				return Object.assign(item, {
					time: item.time.replace(" XM", "")
				});
			}
			if (item.time.includes("XM")) {
				return item;
			}
			return Object.assign(item, {
				time: item.time + " XM"
			});
		});
		
		// data['cruise-ships'] = this.state['cruise-ships'];
		data['offers'] = this.state['offers'];
		data['alert'] = this.state['alert'];
        // data['updated-at'] = this.state['updated-at'].ts;
		data['updated-at'] = DateTime.local().ts;

        this.props.onSave(data);
	}

	reset = () => {
		this.setState(this.getStateFromProps(this.props));
	}

    // <div className="field">
    // 	<h2>Cruise Ships &amp; Excursions</h2>
    // 	<SailingList list={this.state['cruise-ships'] || []} onChange={this.updateCruises} />
    // </div>
    //
    // <Divider />

	render() {
		return <div className="Day">

            {/* <div className="field">
                <h2>Updated At</h2>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                <DateTimePicker value={this.state['updated-at']} onChange={this.updatedAt} format="dd MMM yyyy HH:mm" />
                </MuiPickersUtilsProvider>
            </div> */}

            <div className="field">
                <h2>Alert</h2>
                <FormattedStringList list={this.state['alert'] || []} onChange={this.updateAlert} />
            </div>

            <Divider />

            <div className="field">
				<h2>Service Message</h2>
				<FormattedStringList list={this.state['service-message'] || []} onChange={this.updateServiceMessage} />
			</div>

			<Divider />

			<div className="field">
				<h2>Sailings</h2>
				<SailingList list={this.state.sailings || []} onChange={this.updateSailings} />
			</div>

			<Divider />

			<div className="field">
				<h2>Offers</h2>
				<FormattedStringList list={this.state.offers || []} onChange={this.updateOffers} />
			</div>

			<Divider />

			<div className="field saveButtons">

				<Button startIcon={<SaveIcon />} size="large" onClick={this.save} color="primary" variant="contained">Save</Button>
				<Button startIcon={<EjectIcon />} size="large" onClick={this.reset} color="secondary" variant="contained">Reset</Button>
			</div>

		</div>
	}
}
