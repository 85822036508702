import React from 'react';
import Signin from './Signin';
import DaySelector from './DaySelector';
import Day from './Day';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { DateTime } from 'luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
// import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#145d9f',
        //main: '#042540',
        //main: '#081b66'
    },
    secondary: {
      main: '#333333',
    },
  },
});

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.dataRoot = '/appData-v2/';
    this.dataRootDays = '/appData-v2/days/';
    this.state = {
      user: null,
      currentDate: DateTime.local().startOf('day'),
      loading: true,
      loadedData: null,
      saveSnackOpen: false
    };
  }

  componentDidMount = () => {
    window.firebase.auth().onAuthStateChanged(function(user) {
        this.setState({
          user: user,
          loading: true
        });
        this.loadData(this.state.currentDate);
      }.bind(this));
  }

  signOut = (e) => {
    window.firebase.auth().signOut();
  }

  loadData = (date) => {
    let database = window.firebase.database();
    database.ref(this.dataRootDays + date.toFormat('yyyyMMdd')).once('value').then(function(snapshot) {
      let data = snapshot.val();
      this.setState({
        loadedData: data,
        loading: false
      });
    }.bind(this));
  }

  saveData = (data) => {

    let database = window.firebase.database();
    database.ref(this.dataRootDays + this.state.currentDate.toFormat('yyyyMMdd')).set(data).then(() => {
      this.setState({
        saveSnackOpen: true
      });
    });
  }

  changeDate = (date) => {
    if (!this.state.currentDate.equals(date)) {
      this.setState({
        currentDate: date,
        loadedData: null,
        loading: true
      });
      this.loadData(date);
    }
  }

  createDay = () => {
    let database = window.firebase.database();
    database.ref(this.dataRootDays + this.state.currentDate.toFormat('yyyyMMdd')).set({
      "created_at": window.firebase.database.ServerValue.TIMESTAMP
    }).then(() => {
      this.loadData(this.state.currentDate);
      this.setState({
        loading: true
      });
    });
  }

  saveSnackClosed = () => {
    this.setState({
      saveSnackOpen: false
    });
  }

  render() {

    if (!this.state.user) {

      // sign-in
      return <div className="App">
        <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
          <Typography variant="h6">
          Sark Shipping App CMS
          </Typography>
        </Toolbar>
      </AppBar>
        <Container maxWidth="md">
          <div className="centred">
            <Signin />
          </div>
        </Container>
        </ThemeProvider>
      </div>;
    }

    // content
    let content = <div></div>;
    if (this.state.loading) {

      content = <div className="centred">
        <CircularProgress size={100} />
      </div>;

    } else if (this.state.currentDate && this.state.loadedData) {
      content = <Day onSave={this.saveData} currentDate={this.state.currentDate} data={this.state.loadedData} />;
    } else {
      // if no data for current day - create button to create it
      content = <div className="centred">
        <p>No data found for {this.state.currentDate.toFormat('dd MMM yyyy')}.</p>
        <p><Button onClick={this.createDay} color="primary" variant="contained">
          Create data
          </Button></p>
      </div>;
    }

    return <div className="App">
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">Sark Shipping App CMS</Typography>
            <DaySelector onChange={this.changeDate} />
            <Button color="inherit"><a href="/static">Static Content</a></Button>
            <Button color="inherit"><a href="/cruiseships">Cruise Ships</a></Button>
            <Button onClick={this.signOut} color="inherit">Sign-out</Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
          {content}
        </Container>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  open={this.state.saveSnackOpen}
                  autoHideDuration={2000}
                  onClose={this.saveSnackClosed}
                  action={<IconButton size="small" aria-label="close" color="inherit" onClick={this.saveSnackClosed}>
                    <CloseIcon fontSize="small" />
                  </IconButton>}>
          <Alert onClose={this.saveSnackClosed} severity="success">
            Saved
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </div>;
  }
}
