import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import StaticApp from './StaticApp';
import CruiseShipApp from './CruiseShipApp';

if (window.location.pathname === '/static') {
    ReactDOM.render(<StaticApp />, document.getElementById('root'));
} else if (window.location.pathname === '/cruiseships') {
    ReactDOM.render(<CruiseShipApp />, document.getElementById('root'));
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}


var inactivityTime = function () {
    var time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    function logout() {
        window.firebase.auth().signOut();
    }

    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(logout, 10 * 60 * 1000) // ten mins        
    }
};

window.onload = function() {
    inactivityTime();
  }
  