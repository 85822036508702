import React from 'react';
import TextField from '@material-ui/core/TextField';

export default class StaticFields extends React.Component {

	// expected list, onChange
	// constructor(props) {
	// 	super(props);
	// }

	updateParent = (fieldname, text) => {
		if (this.props.onChange) {
			this.props.onChange(fieldname, text);
		}
	}

	onChangeText = (fieldname, text) => {
		this.updateParent(fieldname, text);
	}

    render() {

        let fieldnames = Object.keys(this.props.fields);

		return <div className="StaticFields">

            <h3>Static Content</h3>
            <p>This content will be availible every day in the app.</p>

            {fieldnames.map((fieldname, index) => {
                return <div key={'fixed-'+index} className="field">

                <TextField multiline={true} maxrows={2} value={this.props.fields[fieldname] || ''} fullWidth={true} label={fieldname.replace('fixed-', '').replaceAll('-', ' ')} onChange={(event) => {
                    this.onChangeText(fieldname, event.target.value);
                }} />

                </div>;
            })}

		</div>
	}
}
